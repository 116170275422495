<template>
  <b-container fluid>
    <b-modal title="Nuevo Devengado" id="my-modal" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="md" hide-footer>
      <!-- <h4 class="px-4 py-4"><b>DEVENGADOS</b></h4> -->
      <form @submit.prevent="saveData">
        <div class="row px-5">
          <div class="col-md-12">
            <div class="mb-4">
              <p>Los devengados que creas aquí son los que te saldrán al momento de gestionar la nómina de tus empleados.</p>
              <label for=" " class="form-label mt-3">Tipo de devengado</label>
              <b-form-select v-model="item.ListaDevengadoId" required value-field="id" text-field="descripcion" :options="listaDevengados"></b-form-select>
            </div>
          </div>
        </div>
        <!-- <template #modal-footer> -->
        <div class="w-100 text-right">
          <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Guardar Devengado</button>
          <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$bvModal.hide('my-modal')">Cancelar</button>
        </div>
        <!-- </template> -->
      </form>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>DEVENGADOS</b></h4>
          </template>
          <div class="text-right px-4 mt-3">
            <!--   -->
            <!-- <b-button v-b-modal.my-modal @click="infoForPerson(item)">Ver más</b-button> -->
            <!-- <button v-b-modal.my-modal class="mx-1 btn btn-success"><i class="fas fa-plus"></i>Agregar Devengado</button> -->
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_departamentos" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Devengado</th>
                  <!-- <th>Estado</th>
                  <th>Opciones</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaDevengados" :key="item.id">
                  <td>{{ item.descripcion }}</td>
                  <!-- <td>{{ item.estado == true ? "Activo" : "Inactivo" }}</td> -->

                  <!-- <td>{{ item.sucursal.descripcion }}</td> -->
                  <!-- <td>
                    <span class="pointer">
                      <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i>
                    </span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      listaItems: [{}],
      logoEmpresa: "",
      usuario: {},
      listaDevengados: [],
      item: {},
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      // await this.getData();
      await this.getListaDevengados();
      core.index();
      window.$("#datatable_departamentos").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async saveData() {
      console.log("saving", this.item);
      try {
        this.item.Estado = true;
        this.item.Eliminado = 0;
        this.item.EmpresaId = this.usuario.empresa.id;

        let res = await this.$store.dispatch("hl_post", {
          path: "Devengado/Devengado/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) {
          this.getData();
          this.$bvModal.hide("my-modal");
        }

        // else alert("Verificar");
      } catch (error) {
        console.log("err", error);
      }
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_delete", {
            path: "Devengado/EliminarDevengado/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      console.log(this.usuario.empresa.id);
      try {
        console.log(".....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "Devengado/GetDevengados/",
        });
        console.log("lista devengados... ", res);
        if (res.length > 0) {
          // this.listaItems = [];
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
    async getListaDevengados() {
      //console.log(this.persona.empresa.id);
      try {
        console.log(".....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "ListaDevengado/GetListaDevengados/",
        });
        console.log("lista devengados... ", res);
        if (res.length > 0) {
          // this.listaDevengados = [];
          this.listaDevengados = res;
        } else {
          this.listaDevengados = [];
        }
      } catch (error) {
        this.listaDevengados = [];
        console.log("err", error);
      }
    },
  },
};
</script>
